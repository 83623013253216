.navbar {
  background-color: var(--white);
  height: 72px;
}

.navbar-brand {
  color: var(--solid) !important;
}

.nav-item {
  padding: 8px 12px;
}

.nav-link, .nav-item {
  color: var(--black) !important;
}

.navbar-brand {
  padding-bottom: 0 !important;
}

.nav-container {
  padding-left: 4rem;
  padding-right: 4rem;
}

a.dropdown-toggle {
    text-decoration: none;
}

a.dropdown-toggle::after {
    display: none;
}

.dropdown-menu.show {
    /*border: 1px solid #EDF4FD;*/
    box-shadow: 0px 8px 24px rgba(137, 166, 202, 0.05);
    border-radius: 8px;
    left: 14px;
}

.nav-active {
    background-color: var(--light-blue) !important;
    color: var(--solid) !important;
    border-radius: 8px;
}

.tenant-identifier {
    font-size: 12px;
    position: absolute;
    top: 42px;
    width: 93px;
    text-align: end;
}