.dropdown-toggle::after {
    display: none;
}

.search-field > .dropdown-toggle {
    /*width: 152px;*/
    height: 44px;

    background: var(--white) !important;
    border: 2px solid var(--light-blue) !important;
    border-radius: 8px;
}

.search-field > .dropdown-toggle {
    color: inherit !important;
}

.search-field > .dropdown-toggle:focus {
    box-shadow: none !important;
}