#transactions-left {
    flex: 0 0 70%;
}

#transactions-right {
    flex: 0 0 30%;
}

.affected-payment {
    background: lightgoldenrodyellow !important;
}