.edit-form-title {
    color: var(--gray);
}

.scrollable-table {
    overflow: auto;
    height: 70vh;
}

.modal-content {
  width: 700px;
}

.react-datetime-picker__wrapper {
  border: 0px;
}


.scrollable-table thead th{
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--white);
}

.badge.bg-secondary {
    background-color: var(--light-gray) !important;
    color: var(--black) !important;
}

.small-input {
    width: 12rem !important;
    min-width: 8rem !important;
}

.icon-cursor {
    cursor: pointer !important;
}

.icon-cursor.disabled {
    cursor: not-allowed !important;
}

.react-datepicker-wrapper {
    width: 120px !important;
}

.react-datepicker-wrapper input {
    min-width: 120px !important;
}

.react-date-picker__wrapper {
    border: none !important;
}

.react-date-picker {
    font-family: var(--bs-font-sans-serif) !important;
}

.react-date-picker__inputGroup__leadingZero {
    font-size: 16px !important;
}
