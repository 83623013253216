.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --solid: #639FF1;
  --light-blue: #EDF4FD;
  --light-gray: #F6F8FB;
  --light-grey: #F6F8FB;
  --white: #FFFFFF;
  --background: #F8F9FC;
  --grey: #9CA4AF;
  --gray: #9CA4AF;
  --black: #192636;
  --red: #DF3B25;
  --light-red: #F6E6E7;
  --green: #006644;
  --light-green: #E3FCEF;
}

h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800 !important;
  font-size: 32px !important;
  line-height: 36px !important;
}

h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800 !important;
  font-size: 24px !important;
  line-height: 32px !important;
}

h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800 !important;
  font-size: 20px !important;
  line-height: 28px !important;
}

.body-bold, button.nav-link.active {
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.body-reg, input, button.nav-link {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.table-text, tr {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.caption-reg {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.caption-bold, label, thead {
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px !important;
}

#app-root{
  margin-left: 5rem;
  margin-right: 5rem;
  margin-top: 2rem;
}

.link-icon, .link-icon-fill {
  cursor: pointer;
}

input {
  height: 44px !important;
  min-width: 360px;
  max-width: 100%;
  background: var(--white);
  border: 2px solid var(--light-blue);
  border-radius: 8px;
  padding: 8px 12px;
}

label {
  color: var(--gray);
}

.modal-header {
  border-bottom: none !important;
  margin-bottom: -1.2rem !important;
}

.text-error {
  color: var(--red) !important;
  font-size: 16px !important;
}

/* table specific */
thead {
  color: var(--gray);
}

table {
  background: var(--white);
  border: 1px solid var(--light-blue) !important;
  border-radius: 8px !important;
  padding: 8px 0px !important;
}

th {
  border-bottom: none !important;
}

tbody {
  border-top: none !important;
}

tr {
  padding: 12px 16px !important;
}

tbody > tr:hover {
  background-color: var(--light-blue) !important;
  cursor: default;
}

/* buttons */
.btn {
  padding: 10px 16px !important;
  border-radius: 8px;
}

.btn:disabled {
  color: var(--gray) !important;
}

.btn-primary {
  background: var(--solid) !important;
  border-color: transparent !important;
}

.btn-secondary {
  background-color: var(--white) !important;
  color: var(--solid) !important;
  border: 1px solid var(--light-blue) !important;
}

.btn-secondary:hover {
  background: radial-gradient(118.04% 318.14% at 123.9% 169.01%, #9375D5 0%, #6A99DC 56.8%, #08A8CB 100%) !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--white);
  background-clip: text;
  text-fill-color: var(--white);
  color: var(--white) !important;
}

.btn-primary:hover {
  background: radial-gradient(118.04% 318.14% at 123.9% 169.01%, #9375D5 0%, #6A99DC 56.8%, #08A8CB 100%) !important;
}

.btn-primary:disabled {
  background: var(--light-gray) !important;
}

.link-icon > svg > path {
  stroke: var(--solid) !important;
}

.link-icon-fill > svg > path {
  fill: var(--solid) !important;
}

.nav-tabs {
  border-bottom: none !important;
}

button.nav-link.active {
  background: var(--light-blue) !important;
  border: none !important;
  border-radius: 8px;
  color: var(--solid) !important;
}

button.nav-link, button.nav-link:hover {
  border: none !important;
}

/* global stuff */
body {
  background-color: var(--background) !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.scrollable-list {
  max-height: 70vh;
  overflow-y: auto;
}