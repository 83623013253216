.pagination-container {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}
.page-link {
  background: transparent;
  border: 0px;
  color: black
}

.pagination-container > svg > path {
  stroke: var(--black);
}

.pagination-container.disabled > svg > path {
  stroke: var(--gray);
}

.pagination-item.pagination-item {
  border: none;
}

.pagination-item.pagination-item.pagination-active {
  background: var(--light-blue);
  color: var(--solid);
  border: 0px solid transparent;
  border-radius: 8px;
}