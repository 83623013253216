.select-search-container {
  min-width: 175px;
  margin-left: 4px;
  height: 44px;
  .css-13cymwt-control {
    border-radius: 8px;
    height: 44px;
    border: 2px solid var(--light-blue) !important;
    .css-1fdsijx-ValueContainer {
      .css-1dimb5e-singleValue {
        height: 40px;
      }
      .css-1jqq78o-placeholder {
        height: 40px;
      }
    }
    .css-1hb7zxy-IndicatorsContainer {
      height: 40px;
    }
  }
}